<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('addCustomer')}}</h2>
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input c-input--text" :class="{'is-invalid': errors.has('name')}">
            <label class="c-input__label" for="name">{{$t('name')}}</label>
            <input v-model="name" class="c-input__field" name="name" type="text" id="name" v-validate
                   data-vv-rules="required|min:3" />
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input c-input--text" :class="{'is-invalid': errors.has('email')}">
            <label class="c-input__label" for="name">{{$t('email')}}</label>
            <input v-model="email" class="c-input__field" name="email" type="email" id="email" v-validate
                   data-vv-rules="required|email" />
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input c-input--text" :class="{'is-invalid': errors.has('username')}">
            <label class="c-input__label" for="username">{{$t('username')}}</label>
            <input v-model="username" class="c-input__field" name="username" type="text" id="username" v-validate
                   data-vv-rules="required|min:3" />
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input c-input--text" :class="{'is-invalid': errors.has('password')}">
            <label class="c-input__label" for="password">{{$t('password')}}</label>
            <input v-model="password" class="c-input__field" name="password" type="text" id="password" v-validate
                   data-vv-rules="required|min:6" />
          </div>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input c-input--select" :class="{'is-invalid': errors.has('locale')}">
            <label class="c-input__label" for="locale">{{$t('language')}}</label>
            <select class="c-input__field c-input__field-select" v-model="locale"
                    id="locale" name="locale" v-validate data-vv-rules="required">
              <option v-for="lang in $options.languages" :value="lang" :key="lang">
                {{$t('languages.' + lang)}}
              </option>
            </select>
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input" :class="{'is-invalid': errors.has('country')}">
            <label class="c-input__label" for="country">{{$t('country')}}</label>
            <select class="c-input__field c-input__field-select" v-model="countryId" name="country" id="country">
              <option :value="null">{{$t('none')}}</option>
              <option v-for="country in countries" :value="country.id" :key="country.id">
                {{$t(country.id)}}
              </option>
            </select>
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input" :class="{'is-invalid': errors.has('copyright')}">
            <label class="c-input__label" for="copyright">{{$t('copyright')}}</label>
            <input v-model="copyright" class="c-input__field" name="copyright" type="text" id="copyright" />
          </div>
        </div>
      </div>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: "addCustomer",
    languages: require('/locales/index'),
    data: function() {
      return {
        name: null,
        username: null,
        email: null,
        locale: 'en',
        countryId: 'country-bel',
        copyright: null,
        password: null,
      };
    },
    computed: {
      countries: function() {
        return this.$store.state.events.countries;
      },
    },
    methods: {
      submit: function() {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            this.$notify.saveAsync(
                this.$t('menu.userProfile'),
                this.$store.dispatch('customer.add', {
                  name: this.name,
                  username: this.username,
                  email: this.email,
                  locale: this.locale,
                  countryId: this.countryId,
                  copyright: this.copyright,
                  password: this.password,
                }),
                (resp) => {
                  this.$navigation.goto({name: 'admin.customer', params: { userId: resp.id}});
                }
            );
          }
        });
      },
    },
  }
</script>

<style scoped>

</style>
