<template>
  <config @submit="update($event)" @remove="remove($event)" :planningDefault="planningDefault"></config>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: 'admin-planningDefault-view',
    components: {
      config: require('./config.vue').default,
    },
    computed: {
      planningDefault: function () {
        return find(this.$store.state.planningDefaults.items, item => item.id === this.$route.params.planningDefaultId);
      },
    },
    methods:{
      update: function(planningDefault) {
        this.$notify.saveAsync(
            this.$t('planningDefault'),
            this.$store.dispatch('planningDefault.update', planningDefault),
            () => {
              this.$navigation.goto({ name: 'admin.user.planningDefaults' });
            }
        );
      },
      remove: function (id) {
        this.$notify.removeAsync(
            this.$t('planningDefault'),
            this.$store.dispatch('planningDefault.remove', { id: id }),
            () => {
              this.$navigation.goto({ name: 'admin.user.planningDefaults' });
            }
        );
      }
    },
  };
</script>

<style>
</style>
