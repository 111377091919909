<template>
  <form ref="passwdForm">
    <div class="o-layout">
      <div class="o-layout__item">
        <scePasswordField v-model="oldPassword" name="oldPassword" label="oldPassword"
                          v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('oldPassword')}" />
      </div>
      <div class="o-layout__item">
        <scePasswordField v-model="newPassword" ref="newPassword" name="newPassword" label="password.new"
                          :config="{showStrength: true}"
                          v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('newPassword')}" />
      </div>
      <div class="o-layout__item">
        <scePasswordField v-model="confirmPassword" name="confirmPassword" label="password.confirmation"
                          v-validate data-vv-as="newPassword" data-vv-rules="required|confirmed:newPassword"
                          :class="{'is-invalid': errors.has('confirmPassword')}" />
      </div>
    </div>
    <button v-on:click.prevent="close()" class="c-button c-button--secondary">
      {{$t('panel.closeModal.button')}}
    </button>
    <button v-on:click.prevent="updatePassword()"
            class="c-button c-button--primary">
      {{$t('panel.continue.button')}}
    </button>
  </form>
</template>

<script>
  import config from 'client/config';

  export default {
    name: "changePassword",
    props: ['userId'],
    data: function() {
      return {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      }
    },
    mounted: function() {
      this.oldPassword = null;
      this.newPassowrd = null;
      this.confirmPassword = null;
    },
    methods: {
      close: function() {
        this.$emit('close');
      },
      updatePassword: function() {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const url = config.root + '/seUsers/' + this.userId + '/setPassword';
            console.log(url);
            this.$notify.msgAsync(
                this.$http.post(url, {
                  oldPasswd: this.oldPassword, newPasswd: this.newPassword
                }),
                'Changing password',
                'Password changed',
                'Password change failed',
                {labels: {async: 'Updating'}}
            );
            this.close();
          }
        });
      },
    }
  }
</script>

<style scoped>
</style>
