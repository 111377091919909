<template>
  <grid :config="{searchFields: ['code', 'description']}" :data="templates">
    <template v-slot:gridAdd>
      <router-link :to="{ name: 'admin.user.planning.template.add'}"
                   class="c-button c-button--secondary c-button--large c-button--icon">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t('importTemplate.add')}}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-2-of-5-at-small">
          {{$t('code')}}
        </div>
        <div class="o-layout__item u-3-of-5-at-small">
          {{$t('description')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-2-of-5-at-small c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.user.planning.template.edit',
         params: {templateId: element.id}}">
          {{element.code}}
        </router-link>
      </div>
      <div class="o-layout__item u-3-of-5-at-small c-list__main-cell">
        {{element.description}}
      </div>
    </template>
  </grid>
</template>

<script>
  import sortBy from 'lodash/sortBy';
  import map from 'lodash/map';

  export default {
    name: 'admin-planning-templates-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      templates: function () {
        return map(sortBy(this.$store.state.importTemplates.items, 'name'), item => {
          return {
            id: item.id,
            code: item.code,
            description: item.description,
          }
        });
      }
    },
    methods: {}
  };
</script>
