<template>
  <div class="c-tabs c-main">
    <h2 v-if="planningDefaultId" class="c-title c-title--page">{{$t('menu.planningDefaults')}}: {{$t(planningDefault.disciplineId)}}</h2>
    <h2 v-else class="c-title c-title--page">{{$t('menu.planningDefaults')}}: {{$t('planningDefault.add')}}</h2>
    <div class="c-tab__tab-bar" v-if="planningDefaultId">
      <router-link v-for="tab in $options.tabs" :key="tab.name"
                   class="c-button c-button--large c-button--ghost c-tabs__tab-link" :to="{ name: tab.name}"
                   :exact="tab.exact">
        {{ $t(tab.label) }}
      </router-link>
    </div>
    <div class="c-tabs__panel c-main u-margin-top-medium">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import find from "lodash/find";

export default {
  name: 'admin-planningDefault-wrapper',
  tabs: [{
    name: 'admin.user.planningDefault.view',
    label: 'settings',
    exact: true,
  }, {
    name: 'admin.user.planning.templates',
    label: 'importTemplates',
    exact: false,
  }],
  computed: {
    planningDefaultId: function () {
      return this.$route.params.planningDefaultId;
    },
    planningDefault: function () {
      return find(this.$store.state.planningDefaults.items, item => item.id === this.$route.params.planningDefaultId);
    },
  },
  methods: {
  },
};

</script>
