<template>
  <grid :config="{searchFields: ['username', 'name']}" :data="users"
        class="u-1-of-1">
    <template v-slot:gridAdd>
      <router-link class="c-button c-button--secondary c-button--large c-button--icon" exact :to="{ name: 'admin.customer.add'}">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t("user.add")}}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-1-of-3-at-small">
          {{$t('username')}}
        </div>
        <div class="o-layout__item u-2-of-3-at-small">
          {{$t('name')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-1-of-3-at-small c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.customer', params: { userId: element.id}}">
          {{element.username}}
        </router-link>
      </div>
      <div class="o-layout__item u-2-of-3-at-small c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.customer', params: { userId: element.id}}">
          {{element.name}}
        </router-link>
      </div>
    </template>
  </grid>
</template>

<script>
  import sortBy from 'lodash/sortBy';

  export default {
    name:'admin-home',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      users: function() {
        return sortBy(this.$store.state.users.items, ['username']);
      }
    },
    methods:{
    }
  };
</script>
