<template>
  <config @submit="update($event)"></config>
</template>

<script>
	export default {
		name: 'admin-planningDefault-add',
    components: {
      config: require('./config.vue').default,
    },
		methods:{
			update: function(config) {
        this.$notify.saveAsync(
            this.$t('planningDefault'),
            this.$store.dispatch('planningDefault.add', {data: config}),
            () => {
              this.$navigation.goto({name: 'admin.user.planningDefaults'});
            }
        );
			},
		},
	};
</script>

<style>
</style>
