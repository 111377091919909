<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{$t('importTemplate.add')}}</h3>
    <importTemplate @submit="update($event)"></importTemplate>
  </div>
</template>


<script>
export default {
  name: "admin-importTemplate-add",
  components: {
    importTemplate: require('./template.vue').default,
  },
  methods:{
    update: function(template) {
      this.$notify.saveAsync(
        this.$t('importTemplate'),
        this.$store.dispatch('importTemplate.add', {data: template}),
        (resp) => {
          this.$navigation.goto({name: 'admin.user.planning.template.edit', params: {templateId: resp.data.id}});
        }
      );
    },
  },
};
</script>

<style>
</style>
