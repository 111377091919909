<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <div class="o-layout" v-if="! id">
        <div class="o-layout__item">
          <sceSelect v-model="disciplineId" :options="disciplines" name="discipline" label="discipline"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('name')}" />
        </div>
      </div>
      <template v-if="! disciplineId">
        <div class="o-layout">
          <div class="o-layout__item">
            {{$t('config.selectDiscipline')}}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="o-layout">
          <label class="c-input__label o-layout__item">{{$t('disciplineConfig.logo')}}</label>
        </div>
        <image-input v-model="logoUrl"/>
        <div class="o-layout u-margin-bottom-medium" v-if="id">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{ $t('eventDiscipline.competitionSettings') }}</h3>
          </div>
          <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.group" >
            <sceSelect v-model="discConfig.groupDisplay" name="groupDisplay" label="groupDisplay"
                       :options="$options.groupDisplay" :config="{}"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.team" >
            <sceSelect v-model="discConfig.teamDisplay" name="teamDisplay" label="teamDisplay"
                       :options="$options.teamDisplay" :config="{}"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="discConfig.finalsReports" name="finalsReports" label="discipline.finalsReports"
                       :options="$options.finalsReports" :config="{}"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.config.useJoker">
            <sceCheckBox v-model="discConfig.useJokers" name="useJokers" label="useJokers"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceCheckBox v-model="discConfig.judgesHideScores" name="judgesHideScores" label="judgesHideScores"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceCheckBox v-model="discConfig.rankingAddGuests" name="rankingAddGuests" label="settings.rankingAddGuests"/>
          </div>
        </div>
        <div class="o-layout u-margin-bottom-medium">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{ $t('rounds') }}</h3>
          </div>

          <div class="o-layout__item">
            <rounds v-model="rounds" :discipline="discipline"/>
          </div>
        </div>

        <planningConfig v-model="config" :discipline="discipline"/>
      </template>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="planningDefault" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.user.planningDefaults'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import map from 'lodash/map'
  import merge from 'lodash/merge'
  import sortBy from 'lodash/sortBy'

  import options from 'client/lib/options'
  import userLib from 'client/lib/user'

  export default {
    name: 'admin-planningDefault',
    components: {
      planningConfig: require('client/components/models/planningConfig').default,
      'image-input': require('client/components/imageInput.vue').default,
      rounds: require('client/components/models/rounds').default,
    },
    props: ['planningDefault'],
    ...options.eventDiscipline,
    data: function() {
      return {
        id: null,
        disciplineId: null,
        logoUrl: null,
        discConfig: {
          groupDisplay: null,
          teamDisplay: 'team',
          finalsReports: 'regular',
          judgesHideScores: false,
          rankingAddGuests: false,
          useJokers: false,
        },
        rounds: [],
        config: {
          grouping: [],
          groupClubs: 'groupClubs',
          keepDistanceClub: true,
          keepDistancePart: 3,
          warmupConfigs: [],
          customSessionTimes: []
        }
      };
    },
    computed: {
      discipline: function() {
        return find(this.$store.state.disciplines.items, d => d.id === this.disciplineId);
      },
      disciplines: function() {
        const configs = this.$store.state.planningDefaults.items;
        return sortBy(map(filter(this.$store.state.disciplines.items,
          item => userLib.matchTags(item) && ! find(configs, c => c.disciplineId === item.id)),
          item => ({
            id: item.id,
            name: this.$t(item.id),
          })), 'name')
      },
    },
    created: function() {
      this.init()
    },
    methods: {
      init: function() {
        if (this.planningDefault) {
          this.id = this.planningDefault.id
          this.disciplineId = this.planningDefault.disciplineId
          this.logoUrl = this.planningDefault.logoUrl
          if (this.planningDefault.discConfig) {
            this.discConfig = this.planningDefault.discConfig
          }
          this.config = merge(this.config, this.planningDefault.config)
          this.rounds = this.planningDefault.rounds ? cloneDeep(this.planningDefault.rounds) : []
        }
      },

      // addRound: function() {},

      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            this.$emit('submit', {
              id: this.id,
              disciplineId: this.disciplineId,
              discConfig: this.discConfig,
              logoUrl: this.logoUrl,
              config: this.config,
              rounds: this.rounds,
            })
          } else {
            this.$notify.warning('Validation errors')
          }
        });
      },
      remove: function () {
        this.$emit('remove', this.id)
      }
    },
  };
</script>
