<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('displayTemplate.edit')}}: {{template.name}}</h2>
    <dtSet @submit="update($event)" @remove="remove($event)" :template="template"></dtSet>
  </div>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: 'admin-displaySet-view',
    components: {
      dtSet: require('./set.vue').default,
    },
    computed: {
      template: function () {
        return find(this.$store.state.displayTemplates.items, item => item.id === this.$route.params.templateId);
      },
    },
    methods:{
      update: function(set) {
        this.$notify.saveAsync(
            this.$t('displayTemplate'),
            this.$store.dispatch('displayTemplate.update', {id: set.id, set: set}),
            () => {
              // this.$navigation.goto({name: 'admin.user.displayTemplates'});
            }
        );
      },
      remove: function (setId) {
        this.$notify.removeAsync(
            this.$t('displayTemplate'),
            this.$store.dispatch('displayTemplate.remove', { id: setId }),
            () => {
              this.$navigation.goto({ name: 'admin.user.displayTemplates' });
            }
        );
      }
    },
  };
</script>

<style>
</style>
