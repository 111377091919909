<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('menu.userProfile')}}</h2>
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="name" name="name" label="name"
                        v-validate data-vv-rules="required|min:3" :class="{'is-invalid': errors.has('name')}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceEmailField v-model="email" name="email" label="email"
                        v-validate data-vv-rules="required|email" :class="{'is-invalid': errors.has('email')}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="username" name="username" label="username"
                        v-validate data-vv-rules="required|min:3" :class="{'is-invalid': errors.has('username')}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="locale" name="language" label="language" :options="$options.languages" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="countryId" name="country" label="country"
                     :options="countries" :config="{optionLabel: 'id', emptyLabel: 'none'}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small o-flex o-flex--align-end">
          <button class="c-button c-button--primary c-button--large u-margin-bottom-small" v-on:click.prevent="changePassword()">
            {{$t('password.change')}}
          </button>
        </div>
      </div>



      <div class="o-layout">
        <label class="c-input__label o-layout__item">{{$t('profile.logo')}}</label>
      </div>
      <image-input v-model="logoUrl"/>

      <h3 class="c-subtitle u-margin-top-medium">{{ $t('profile.publicSettings') }}</h3>
      <div class="o-layout">
        <div class="o-layout__item">
          <sceCheckBox name="usePublic" label="profile.usePublic" v-model="usePublic" />
        </div>
        <div v-if="usePublic && user" class="o-layout__item">
          <div class="c-input--extra-info">
            <a :href="publicUrl()">{{publicUrl()}}</a>
          </div>
          <div class="c-input--extra-info">
            {{ $t('profile.public.note') }}
          </div>
        </div>
        <div class="o-layout__item">
          <div class="c-input--extra-info">
            {{ $t('profile.public.description') }}
          </div>
        </div>
      </div>

      <h3 class="c-subtitle u-margin-top-medium">{{ $t('profile.reportSettings') }}</h3>
      <div class="o-layout">
        <div class="o-layout__item">
          <sceTextField v-model="copyright" name="copyright" label="copyright" />
        </div>
        <div class="o-layout__item">
          <div class="c-tabs c-main">
            <div class="c-tab__tab-bar">
              <button v-for="tab in $options.reportingTabs" :key="tab"
                      class="c-button c-button--large c-button--ghost c-tabs__tab-link"
                      :class="{'is-active': reportingTab === tab}"
                      v-on:click.prevent="reportingTab = tab">
                {{$t(tab)}}
              </button>
            </div>
          </div>
        </div>

        <div class="o-layout__item" v-if="reportingTab === 'header'">
          <sceCheckBox name="useHeader" label="profile.reportHeader.checkbox" v-model="useHeader" />
          <sceNumberField v-if="useHeader" v-model="reports.headerSize" label="headerSize" min="1.5" max="5" step="0.1"/>
          <prism-editor v-if="useHeader" id="headerTemplate" v-model="reports.header"
                        language="html" class="u-margin-bottom-medium"/>
        </div>
        <div class="o-layout__item" v-if="reportingTab === 'footer'">
          <sceCheckBox name="useFooter" label="profile.reportFooter.checkbox" v-model="useFooter" />
          <sceNumberField v-if="useFooter" v-model="reports.footerSize" label="footerSize" min="1.5" max="5" step="0.1"/>
          <prism-editor v-if="useFooter" id="footerTemplate" v-model="reports.footer" language="html"/>
        </div>
        <div class="o-layout__item" v-if="reportingTab === 'certificate'">
          <sceCheckBox name="useCertificate" label="profile.reportCertificate.checkbox" v-model="useCertificate" />
          <prism-editor v-if="useCertificate" id="certTemplate" v-model="reports.certificate" language="html"/>
        </div>
        <div class="o-layout__item" v-if="reportingTab === 'bib'">
          <sceCheckBox name="useBib" label="profile.reportBib.checkbox" v-model="useBib" />
          <prism-editor v-if="useBib" id="certTemplate" v-model="reports.bib" language="html"/>
        </div>
        <div class="o-layout__item" v-if="reportingTab === 'css'">
          <sceCheckBox name="useCss" label="profile.reportCss.checkbox" v-model="useCss" />
          <prism-editor v-if="useCss" id="cssTemplate" v-model="reports.css" language="css"/>
        </div>
      </div>

      <h3 v-if="isAdmin" class="c-subtitle u-margin-top-medium">Admin settings</h3>
      <div v-if="isAdmin" class="o-layout">
        <div class="o-layout__item">
            <sceMultiSelect v-model="tags" :options="$options.tags" name="tags"
                            placeholder="select.tags" label="tags" />
        </div>
        <div class="o-layout__item">
          <h3 v-if="isAdmin" class="c-title c-title--page-section">{{ $t('profile.federationImport') }}</h3>
          <sceCheckBox name="importEnabled" label="federationImport.enable" v-model="fedImport.enabled" />
        </div>
        <template v-if="fedImport.enabled">
          <div class="o-layout__item u-1-of-2-at-small">
            <sceTextField v-model="fedImport.eventsURL" name="importEventsURL" label="federationImport.eventsURL"
                          v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('importEventsURL')}" />
            <sceTextField v-model="fedImport.disciplineUrl" name="importDisciplineUrl" label="federationImport.disciplineUrl"
                          v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('importDisciplineUrl')}" />
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="fedImport.authMethod" name="importAuthMethod" label="federationImport.authMethod"
                       :options="$options.authMethods" :config="{localized: false}" />

            <template v-if="fedImport.authMethod === 'basic'">
              <sceTextField v-model="fedImport.authUsername" name="importAuthUsername" label="username"
                            v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('importAuthUsername')}" />
              <scePasswordField v-model="fedImport.authKey" name="importAuthPassword" label="password"
                            v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('importAuthPassword')}" />
            </template>
            <template v-if="fedImport.authMethod === 'bearer'">
              <scePasswordField v-model="fedImport.authKey" name="importAuthToken" label="token"
                                v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('importAuthToken')}" />
            </template>
          </div>
        </template>

        <div class="o-layout__item">
          <h3 v-if="isAdmin" class="c-title c-title--page-section">{{ $t('profile.federationExport') }}</h3>
        </div>
        <div class="o-layout__item">
          <div class="o-layout" v-for="(item, i) in fedExport" :key="'method-'+i">
            <div class="o-layout__item u-1-of-10-at-small">
              {{i+1}}.
            </div>
            <div class="o-layout__item u-2-of-5-at-small">
              <sceTextField v-model="item.label" name="'methodLabel-'+i" v-validate data-vv-rules="required"
                            :class="{'is-invalid': errors.has('methodLabel-'+i)}" />
            </div>
            <div class="o-layout__item u-1-of-5-at-small">
              <sceSelect v-model="item.type" name="'methodType-'+i"
                         :options="$options.fedExportTypes"/>
            </div>
            <div class="o-layout__item u-1-of-5-at-small">
              <sceSelect v-model="item.method" name="'methodMethod-'+i"
                         :options="$options.liveScoreOptions"/>
            </div>
            <div class="o-layout__item u-1-of-10-at-small">
              <button class="c-button c-button--primary c-button--small" v-on:click.prevent="deleteExportMethod(i)">
                {{$t('delete')}}
              </button>
            </div>
          </div>
        </div>
        <div class="o-layout__item">
          <button class="c-button c-button--primary c-button--small" v-on:click.prevent="addExportMethod()">
            {{$t('add')}}
          </button>
        </div>
      </div>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>
    <modal :shown="changePwd" @closed="changePwd = false">
      <change-password @close="changePwd = false" :userId="user.id" />
    </modal>
  </div>
</template>

<script>
  import form from '@/lib/form'
  import options from '@/lib/options'
  import userLib from '@/lib/user'

  import clone from 'lodash/cloneDeep'

  import PrismEditor from 'vue-prism-editor'
  import "prismjs"
  import "prismjs/themes/prism.css"

  export default {
    name: "profile",
    components: {
      PrismEditor,
      'modal': require('client/components/modal.vue').default,
      'changePassword': require('./changePassword.vue').default,
      'image-input': require('client/components/imageInput.vue').default,
    },
    languages: form.idNamePairs(require('/locales/index'), 'languages.'),
    tags: form.idNamePairs(['all', 'aus', 'kngu', 'gymfed', 'ffgym', 'kbt', 'nolimits','singapore', 'sgis', 'stembert']),
    reportingTabs: ['header', 'footer', 'certificate', 'bib', 'css'],
    authMethods: [
      {id: 'none', name: 'No Authentication'},
      {id: 'basic', name: 'Basic'},
      {id: 'bearer', name: 'Bearer token'}],

    fedExportTypes: form.idNamePairs(['live', 'gymfed', 'judges']),
    ...options.profile,
    data: function() {
      return {
        name: null,
        username: null,
        email: null,
        locale: 'en',
        countryId: 'country-bel',
        changePwd: false,
        copyright: null,
        logoUrl: null,
        reportingTab: 'header',
        useHeader: false,
        useFooter: false,
        useCertificate: false,
        useBib: false,
        useCss: false,
        reports: {
          header: null,
          headerSize: 4.1,
          footer: null,
          footerSize: 1.6,
          certificate: null,
          bib: null,
          css: null,
        },
        tags: [],
        fedImport: {
          enabled: false,
          eventsUrl: null,
          disciplineUrl: null,
          authMethod: 'none',
          authKey: null,
          authUsername: null,
        },
        fedExport: [],
        usePublic: false,
      }
    },
    computed: {
      user: function() {
        const userId = this.$route.params.userId;
        return this.$store.getters.user(userId);
      },
      countries: function() {
        return this.$store.state.events.countries;
      },
      isAdmin: function() {
        return userLib.checkRole('admin');
      },
    },
    created: function() {
      this.name = this.user.name;
      this.username = this.user.username;
      this.email = this.user.email;
      this.locale = this.user.locale;
      this.countryId = this.user.countryId;
      this.copyright = this.user.copyright;
      this.logoUrl = this.user.logoUrl;
      this.usePublic = this.user.usePublic

      if (this.isAdmin) {
        this.tags = form.idNamePairs(this.user.tags);
        if (this.user.configuration) {
          if (this.user.configuration.federationImport) {
            this.fedImport = this.user.configuration.federationImport
          }
          if (this.user.configuration.federationExport) {
            this.fedExport = this.user.configuration.federationExport
          }
        }
      }

      if (this.user.reports) {
        this.reports = clone(this.user.reports)
        this.useHeader = !! this.user.reports.header
        this.useFooter = !! this.user.reports.footer
        this.useCertificate = !! this.user.reports.certificate
        this.useBib = this.user.reports.bib
        this.useCss = !! this.user.reports.css
      }
    },
    methods: {
      changePassword: function() {
        this.changePwd = true
      },
      addExportMethod: function() {
        this.fedExport.push({
          label: this.$t('new'),
          type: 'live',
          method: 'ScoreExpress',
        })
      },
      deleteExportMethod: function(index) {
        this.fedExport = this.fedExport.filter((item, i) => i !== index)
      },
      publicUrl: function() {
        if (! this.user) return null
        return 'https://public.scoreexpress.be/clubs/' + this.user.id
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const reports = clone(this.reports)
            if (! this.useHeader) {
              delete(reports.header)
              delete(reports.headerSize)
            }
            if (! this.useFooter) {
              delete(reports.footer)
              delete(reports.footerSize)
            }
            if (! this.useCertificate) {
              delete(reports.certificate)
            }
            if (! this.useBib) {
              delete(reports.bib)
            }
            if (! this.useCss) {
              delete(reports.css)
            }

            const data = {
              id: this.user.id,
              name: this.name,
              username: this.username,
              email: this.email,
              locale: this.locale,
              countryId: this.countryId,
              copyright: this.copyright,
              logoUrl: this.logoUrl,
              reports,
              usePublic: this.usePublic,
            };

            if (this.isAdmin) {
              data.tags = this.tags.map(tag => tag.id);
              data.configuration = {
                federationImport: this.fedImport,
                federationExport: this.fedExport,
              }
            }

            this.$notify.saveAsync(
                this.$t('menu.userProfile'),
                this.$store.dispatch('user.update', data)
            );
          }
        });
      },
    },
  }
</script>

<style scoped>

</style>
