<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-small">
      <div class="o-layout">
        <div class="o-layout__item">
          <sceTextField v-model="code" name="code" label="code" inputName="code"
                        v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('code')}" />
          <sceTextArea v-model="description" name="description" label="description" inputName="description" rows="2" />

        </div>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('category')}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceNumberField v-model.number="category.minAge" name="minAge" label="category.minimalAge"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                          :class="{'is-invalid': errors.has('minAge')}" min="0" max="120" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceNumberField v-model.number="category.maxAge" name="maxAge" label="category.maximalAge"
                          v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                          :class="{'is-invalid': errors.has('maxAge')}" min="0" max="120" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceSelect v-model="category.gender" name="gender" label="gender" inputName="genderField"
                     :options="$options.genderOptions" v-validate data-vv-rules="required"
                     :class="{'is-invalid': errors.has('gender')}"/>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium">
          <sceSelect v-model="category.participantType" name="participantType" label="participantType"
                     inputName="participantTypeField" :options="$options.participantTypeOptions"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('participantType')}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium" v-if="category.participantType === 'group'">
          <sceNumberField v-model.number="category.groupSize.min" name="groupSizeMin" label="category.minimalGroupSize"
                          v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                          :class="{'is-invalid': errors.has('groupSizeMin')}" min="0" max="100" step="1" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-medium" v-if="category.participantType === 'group'">
          <sceNumberField v-model.number="category.groupSize.max" name="groupSizeMiax" label="category.maximalGroupSize"
                          v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                          :class="{'is-invalid': errors.has('groupSizeMax')}" min="0" max="100" step="1" />
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model="category.scorePublication" name="scorePublication" label="scorePublication"
                     :options="$options.scorePublicationOptions" v-validate data-vv-rules="required" />
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model="category.rankingPublication" name="rankingPublication" label="rankingPublication"
                     :options="$options.rankingPublicationOptions" v-validate data-vv-rules="required" />
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-medium">
          <sceSelect v-model="categoryRound.formatId" name="format" label="competitionFormat"
                     inputName="formatField" :options="formats" v-on:change="setRoundOptions()"
                     v-validate data-vv-rules="required"
                     :class="{'is-invalid': errors.has('format')}"
                     :config="{groups: true, localized: false, emptyLabel: 'none'}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-medium o-flex o-flex--align-end">
          <div class="c-input--extra-info">
            {{getFormatDescription(categoryRound.formatId)}}
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-medium">
          <sceSelect v-model="categoryRound.tieBreakId" name="tie" label="tieBreak"
                     :config="{localized: false, emptyLabel: 'none'}"
                     inputName="tieField" :options="[{id: null, name: 'none'}, ...tieBreaks]"/>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="categoryRound.teamMethod" name="teamMethod"
                     :config="{ emptyLabel: 'none'}"
                     label="category.teamMethod" :options="$options.teamMethodOptions"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceNumberField v-if="categoryRound.teamMethod" v-model="categoryRound.teamCount" name="teamCount"
              :label="'category.teamCount.' + categoryRound.teamMethod" min="0" max="100" step="1" />

        </div>

        <div class="o-layout__item u-1-of-2-at-medium" v-for="field of categoryRoundOptions" :key="field.config.label">
          <div v-if="field.config.inputType !== 'copy'" :is="'custom-field-' + field.config.inputType"
               v-model="field.value" :name="'round-' + field.config.label" :config="field.config"/>
        </div>

        <div class="o-layout__item u-1-of-2-at-medium" v-for="option of categoryRoundOptions" :key="option.label">
          <div class="c-input" v-if="option.inputType === 'select'">
            <label class="c-input__label" :for="'option-' + option.label">
              {{$t('categoryOption.' + option.label)}}
            </label>
            <select class="c-input__field c-input__field-select" v-model="option.value"
                    :id="'option-' + option.label">
              <option value="">
                {{$t('none')}}
              </option>
              <option v-for="ov of option.values" :value="ov.value" :key="ov.value">
                {{$t('categoryOption.' + ov.label)}}
              </option>
            </select>
          </div>
          <sceNumberField v-if="option.inputType === 'number'" v-model.number="option.value"
                          :name="'option-' + option.label" :label="'categoryOption.' + option.label"
                          :min="option.min" :max="option.max" :step="option.step" />
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <h4 class="c-title c-title--page-section">{{$t('categoryRound.singleAwards')}}</h4>

          <div class="o-layout">
            <div class="o-layout__item">
              <sceSelect v-model="categoryRound.singleAwardsType" name="singleAwardsType"
                         :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
            </div>
          </div>
          <categoryAwards v-if="categoryRound.singleAwardsType" namePrefix="singleAwards"
                          v-model="categoryRound.singleAwards" />
        </div>

        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.team">
          <h4 class="c-title c-title--page-section">{{$t('categoryRound.teamAwards')}}</h4>

          <div class="o-layout">
            <div class="o-layout__item">
              <sceSelect v-model="categoryRound.teamAwardsType" name="teamAwardsType"
                         :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
            </div>
          </div>
          <categoryAwards v-if="categoryRound.teamAwardsType" namePrefix="teamAwards"
                          v-model="categoryRound.teamAwards" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small"
             v-if="discipline.exerciseTiming === 'category' || discipline.exerciseTiming === 'exercise'">
          <h4 class="c-title c-title--page-section">{{$t('category.exerciseTiming')}}</h4>

          <sceNumberField v-if="discipline.exerciseTiming === 'category'"
                          v-model.number="categoryRound.exerciseTime" name="exerciseTime"
                          label="session.schedule.exerciseTime" min="0" max="1200" step="1" />

          <div class="o-layout" v-if="discipline.exerciseTiming === 'exercise' && categoryRound.formatId">
            <div v-for="exercise of categoryRound.exerciseTiming" :key="exercise.type" class="o-layout__item">
              <sceNumberField v-model.number="exercise.time" :name="'exerciseTime:' + exercise.type"
                              :label="'exercise.type.'+exercise.type" min="0" max="1200" step="1" />
            </div>
          </div>
          <p v-if="discipline.exerciseTiming === 'exercise' && ! categoryRound.formatId">
            {{ $t('category.exerciseTiming.noFormat') }}
          </p>
        </div>
      </div>

      <h4 class="c-title c-title--page-section">{{$t('session')}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.rotationType === 'rotation'">
          <sceSelect v-model="session.rotationType" name="rotationType"
                     label="session.rotationType" :options="$options.rotationTypes"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('rotationType')}" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-padding-top-medium" v-if="discipline.rotationType!=='mixed'">
          <sceCheckBox v-model="session.rotationFree" name="rotationFree" label="session.rotationFree" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small u-padding-top-medium" v-if="discipline.config.allowPanelSwitch">
          <sceCheckBox v-model="session.switchPanels" name="switchPanels" label="session.switchPanels" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="session.warmup" name="warmup" :config="{ emptyLabel: 'none', localized: false }"
                     label="session.warmupConfig" :options="warmupOptions()" />
        </div>
      </div>

    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.user.planning.templates'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import options from 'client/lib/options';
import filter from "lodash/filter";
import category from "@/lib/category";
import map from "lodash/map";
import merge from "lodash/merge"
import customFieldsLib from "@/lib/customFields";

export default {
  name: 'import-template',
  components: {
    categoryAwards: require('client/components/models/awards.vue').default,
  },
  props: ['id'],
  ...options.category,
  ...options.session,
  data: function() {
    return {
      code: null,
      description: null,
      category: {
        participantType: 'clubMember',
        groupSize: {
          min: 2,
          max: 2,
        },
        minAge: 0,
        maxAge: 120,
        gender: 'mixed',
        scorePublication: 'full',
        rankingPublication: 'full',
      },
      categoryRound: {
        formatId: null,
        tieBreakId: null,
        teamMethod: 'event',
        teamCount: 3,
        exerciseTime: null,
        exerciseTiming: [],
        singleAwardsType: null,
        singleAwards: [],
        teamAwardsType: null,
        teamAwards: [],
      },
      categoryRoundOptions: {},
      session: {
        rotationType: 'fixed',
        rotationFree: false,
        switchPanels: false,
        warmup: null,
      },
    }
  },
  computed: {
    importTemplate: function() {
      return find(this.$store.state.importTemplates.items, i => i.id === this.id)
    },
    planningDefault: function () {
      return find(this.$store.state.planningDefaults.items,
        item => item.id === this.$route.params.planningDefaultId)
    },
    discipline: function() {
      return find(this.$store.state.disciplines.items,
        i => i.id === this.planningDefault.disciplineId)
    },
    formats: function() {
      let formats = []

      const activeFormats = sortBy(filter(this.$store.state.importTemplates.formats,
        f => ! f.deprecated), ['index'])
      if (activeFormats.length) {
        formats.push({
          name: this.$t('active'),
          elements: activeFormats,
        })
      }

      const inActiveFormats = sortBy(filter(this.$store.state.importTemplates.formats,
        f => f.deprecated), ['index'])
      if (inActiveFormats.length) {
        formats.push({
          name: this.$t('inactive'),
          elements: inActiveFormats,
        })
      }
      return formats
    },
    tieBreaks: function() {
      return sortBy(this.$store.state.importTemplates.tieBreaks, 'index')
    }
  },
  created: function() {
    if (this.importTemplate) {
      this.code = this.importTemplate.code
      this.description = this.importTemplate.description
      this.category = merge(this.category, cloneDeep(this.importTemplate.data.category))
      this.categoryRound = merge(this.categoryRound, cloneDeep(this.importTemplate.data.categoryRound))
      this.session = merge(this.session, cloneDeep(this.importTemplate.data.session))
    }

    this.setRoundOptions()
  },
  watch: {
    'categoryRound.formatId': {
      immediate: true,
      handler: 'setExerciseTimes'
    },
  },
  methods: {
    getFormatDescription: function(formatId) {
      const format = find(this.$store.state.importTemplates.formats, item => item.id === formatId)
      return format ? format.description : this.$t('category.skipRound.description')
    },
    warmupOptions: function() {
      let result = []

      if (this.planningDefault?.config?.warmupConfigs) {
        result = this.planningDefault.config.warmupConfigs.map(wc => ({id: wc.name, name: wc.name}))
      }

      return result
    },
    setRoundOptions: function() {
      let options = []

      let config = {}
      if (this.importTemplate?.data.categoryRound.config) {
        config = this.importTemplate.data.categoryRound.config
      }
      const format = find(this.$store.state.eventDiscipline.formats, i => i.id === this.categoryRound.formatId)

      if (this.discipline.options) {
        options = this.discipline.options
      }
      if (format?.options) {
        options = options.concat(format.options)
      }

      this.categoryRoundOptions = customFieldsLib.getFields(options, config, format)
    },

    setExerciseTimes: function() {
      if (this.categoryRound.formatId && this.discipline.exerciseTiming === 'exercise') {
        const format = this.$store.state.importTemplates.formats.find(f => f.id === this.categoryRound.formatId)
        const exercises = category.getExerciseTypes(format)
        const prevExerciseTiming = this.categoryRound.exerciseTiming || []
        this.categoryRound.exerciseTiming = map(exercises, exerciseTypeId => {
          const time = find(prevExerciseTiming, time => time.type === exerciseTypeId)
          return time ? time : {type: exerciseTypeId, time: undefined}
        })
      }
    },
    submit: function () {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          const data = {
            id: this.id,
            code: this.code,
            description: this.description,
            disciplineId: this.discipline.id,
            owner_id: this.planningDefault.owner_id,
            data: {
              category: this.category,
              categoryRound: this.categoryRound,
              session: this.session,
            },
          }
          data.data.categoryRound.config = customFieldsLib.getValues(this.categoryRoundOptions)

          this.$emit('submit', data)
        } else {
          this.$notify.warning('Validation errors')
        }
      })
    },
    remove: function () {
      this.$modal.show({
        title: this.$t('delete.confirm.title'),
        message: this.$t('delete.confirm.text', {item: this.code}),
        onConfirm: () => {
          this.$emit('remove', this.id)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
