<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{$t('importTemplate.edit')}}</h3>
    <importTemplate :id="template.id" @submit="update($event)" @remove="remove($event)"></importTemplate>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  name: 'admin-importTemplate-view',
  components: {
    importTemplate: require('./template.vue').default,
  },
  computed: {
    template: function () {
      return find(this.$store.state.importTemplates.items, item => item.id === this.$route.params.templateId);
    },
  },
  methods:{
    update: function(data) {
      this.$notify.saveAsync(
        this.$t('importTemplate'),
        this.$store.dispatch('importTemplate.update', data),
        () => {
          this.$navigation.goto({name: 'admin.user.planning.templates'});
        }
      );
    },
    remove: function (id) {
      this.$notify.removeAsync(
        this.$t('importTemplate'),
        this.$store.dispatch('importTemplate.remove', { id }),
        () => {
          this.$navigation.goto({ name: 'admin.user.planning.templates' });
        }
      );
    }
  },
};
</script>
