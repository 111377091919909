<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('menu.planningDefaults')}}</h2>
    <div class="o-layout">
      <div class="o-layout__item">
        <p class="c-input--extra-info">
          {{$t('planningConfig.explanation')}}
        </p>
      </div>
    </div>
    <grid :config="{searchFields: ['discipline']}" :data="gridData">
      <template v-slot:gridAdd>
        <router-link :to="{ name: 'admin.user.planningDefault.add'}"
                     class="c-button c-button--secondary c-button--large c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{$t("planningDefault.add")}}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item">
            {{$t('discipline')}}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.user.planningDefault.view',
           params: {planningDefaultId: element.id}}">
            {{$t(element.discipline)}}
          </router-link>
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';
  import map from 'lodash/map';

  export default {
    name: 'admin-planningConfigs-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    data: function() {
      return {
        gridData: [],
      };
    },
    computed: {
      disciplines: function() {
        return this.$store.state.disciplines.items;
      },
      planningDefaults: function () {
        return this.$store.state.planningDefaults.items;
      },
    },
    watch: {
      planningDefaults: {
        immediate: true,
        handler: function() {
          this.updateGridData();
        },
      },

    },
    methods: {
      updateGridData: function() {
        this.gridData = sortBy(map(this.planningDefaults, item => {
          const discipline = find(this.disciplines, d => d.id === item.disciplineId);
          return {
            id: item.id,
            discipline: this.$t(discipline.id),
          };
        }), 'discipline');
      },
    }
  };
</script>
